import React from 'react';
import "./PricingCardComponent.css";
import checkmarkIcon from "../assets/checkmark_icon.svg";


type Props = {
  price: string,
  benefits: string[],
  darkMode?: boolean,
  membershipName: string,
  description: string,
  ctaUrl: string,
}

const PricingCardComponent = (props: Props) => {
  return (
    <div className="card-container">
      <div className="top-half-card-content">
        <h3>
          {props.membershipName}
        </h3>
        <div className="price-box">
          <div className="price-title">{props.price}</div>
          <div className="term-box">
            <div className="regular-font-style">per member</div>
            <div className="regular-font-style">per month</div>
          </div>
        </div>
        <div className="regular-font-style">
          {props.description}
        </div>
        <button
          className="get-started-button"
          onClick={() => { window.location.href = props.ctaUrl }}>
          Get Started
        </button>
      </div>
      <div className="bottom-half-card-content">

        <div className="benefits-title">
          Benefits
        </div>
        {
          props.benefits.map((benefit, index) => {
            return <div className="benefit" key={index}>
              <img
                className="checkmark"
                src={checkmarkIcon}
                alt="Icon of a checkmark"
              />
              <div className="benefit-name">
                {benefit}
              </div>
            </div>
          })
        }
      </div>

    </div>
  )
}

export default PricingCardComponent